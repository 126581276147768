<template>
  <div class="music-detail-container">
    <div class="crumb-wapper">
      <vf-breadcrumb :data="breadData"></vf-breadcrumb>
    </div>
    <div class="content" v-loading="loading">
      <div class="music-info">
        <div class="header-img" @click="handlePlayerMusic({ ...musicData, type: 2, labels: musicData.label })">
          <img :src="musicData.logo" alt="" class="img">
          <div class="audio-status">
            <img v-if="playing && musicData.music_id == musicInfo.music_id" src="~@/assets/svg/operation/play_icon.svg"/>
            <img v-else src="~@/assets/svg/operation/pause_icon.svg"/>
          </div>
        </div>
        <h3>{{musicData.name}}</h3>
        <h4 v-if="musicData.label && musicData.label.length">{{ musicData.label.map(i => i.name).join('，')}}</h4>
        <span @click="dialogVisibleAuth=true">如何获取授权证书？</span>
      </div>
      <div>
        <div class="scene-info">
          <div
            v-for="(item,index) in sceneList"
            :key="index" class="scene-items"
            :class="{'scene-active': chooseSceneList.includes(item.sk_id)}"
            @click="handleChooseScene(item)"
          >
            <div class="name">
              <div>
                <span>{{item.name}}</span>
                <el-tooltip placement="top">
                  <div slot="content" class="tooltip-c">
                    {{item.desc}}
                  </div>
                  <img class="qus" src="~@/assets/svg/question-icon.svg" alt="">
                </el-tooltip>
              </div>
              <i>开通企业会员<span>立省￥{{parseInt(item.price / 100)}}</span></i>
            </div>
            <div class="price">￥{{parseInt(item.price / 100)}}</div>
          </div>
        </div>
        <div class="pay-info">
          <div class="tip"><i class="el-icon-warning" style="color: #FF8E14"></i>所有授权场景均支持随片永久，投放全球，不得进行转授权</div>
          <div class="pay">
            <span>应付金额：<span><i>￥</i>{{totalPrice ? Number(totalPrice / 100) : '-'}}</span></span>
            <el-button class="retail-pay" :loading="payLoading" @click="handlePay">立即支付</el-button>
          </div>
        </div>
      </div>
      <div class="vip-info">
        <div class="name">{{packageInfo.name}}
          <el-tooltip :content="`（年付:${packageInfo.per_price}元×12个月）`" placement="top">
            <img src="~@/assets/svg/question_white_icon.svg" alt="">
          </el-tooltip>
        </div>
        <!-- 包月年付价格99 样式上要求不展示分 若后期价格调整有分 需去掉强转 -->
        <div class="price"><span>￥</span>{{parseInt(packageInfo.per_price)}}<i> /月</i></div>
        <span>按年收费</span>
        <div class="desc">
          <div><img src="~@/assets/image/music/bg_icon_01.svg" alt=""><p>全年365天随心用</p></div>
          <div><img src="~@/assets/image/music/bg_icon_02.svg" alt=""><p>全站所有素材任意下</p></div>
          <div><img src="~@/assets/image/music/bg_icon_03.svg" alt=""><p>全球 全场景全覆盖</p></div>
        </div>
        <div class="pay">
          <p id="lijikaitong" data-agl-cvt="7" @click="immediatePayment">立即开通</p>
        </div>
        <span style="cursor: pointer;" @click="goPackage">更多会员开通方案 ></span>
      </div>
    </div>
    <section class="recommend" v-if="similarList.length">
      <p class="title">相似音乐推荐</p>
      <music-list v-loading="similarLoading" :list="similarList" :isSimilar="false" :isMidia="false"></music-list>

    </section>
    <!-- 致用户的一封信 -->
    <Letter :showLetter="showLetter" v-show="showLetter" @closeLetterDialog="closeLetter" />
    <DialogForPay
      :isRetail="isRetail"
      :dialogVisible="dialogVisiblemember"
      :payGoodInfo="payGoodInfo"
      :wxPayQrCode="wxPayQrCode"
      :aliPayQrCode="aliPayQrCode"
      @close="handlePayClose"
      @publicSubmit="handlePublicSubmit"
    ></DialogForPay>
    <DialogFonrHowAuth :dialogVisible="dialogVisibleAuth" @close="dialogVisibleAuth=false"></DialogFonrHowAuth>
  </div>
</template>

<script>
import Buttons from '@/layout/mixins/Buttons'
import {
  fetchOrderPayByAliPay,
  fetchPackageList,
  fetchOrderPayByWxPay,
  fetchOrderPayStatusByPackage
} from '@/api/package'
import { fetchMusicSimilar } from "@/api/music";
import { fetchMusicDetail, addMusicOrder, fetchMusicPayCode, fetchPayCodeResult } from '@/api/music'
import Letter from '@/views/package/components/Letter'
import DialogForPay from '@/components/DialogForPay'
import { payReloadTime } from '@/config'
import DialogFonrHowAuth from '../components/DialogForHowAuth.vue'
import MusicList from '../components/MusicList.vue';
import VfBreadcrumb from '@/components/VfBreadcrumb.vue'
import { mapGetters } from 'vuex'
import { getToken } from '@/utils/author'
import { formDateTime } from '@/utils/formDate.js'
import { send_eventApi } from '@/utils/common.js'

export default {
  name: 'MusicDetail',
  components: {
    MusicList,
    Letter,
    DialogForPay,
    VfBreadcrumb,
    DialogFonrHowAuth
  },
  data() {
    return {
      breadData: [
        {breadcrumbName: 'PlayList', path: '/'},
        {breadcrumbName: '音乐', path: '/music'},
        {breadcrumbName: 'name'}
      ],
      similarList: [],
      loading: false,
      similarLoading: false,
      payLoading: false,
      totalPrice: 119900,
      chooseSceneList: ['A2009'],
      packageInfo: {
        name: '连续包月-年付',
        per_price: 99
      },
      showLetter: false,
      dialogVisiblemember: false,
      dialogVisibleAuth: false,
      wxPayQrCode: '',
      aliPayQrCode: '',
      // 定时器
      wxPayTimer: null,
      aliPayOrderTimer: null,
      retailTimer: null,
      isRetail: false,
      payGoodInfo: {},
      musicData: {},
      sceneList:[]
    }
  },
  mixins: [Buttons],
  computed: {
    ...mapGetters(['userInfo']),
    playing() {
      return !!this.$store.state.music.isPlaying
    },
    musicInfo() {
      return this.$store.state.music.tmpMusicInfo
    },

  },
  watch: {
    '$route.params.id': {
      handler() {
        // this.getPackageList()
        this.getDetail()
        this.getSimilarMusicList()
        this.totalPrice = 119900
        this.chooseSceneList = ['A2009']
      }
    },
    'userInfo.user_id': {
      handler(newVal) {
        if(newVal){
          this.getPackageList()
        }
      }
    }
  },
  mounted() {
    if(this.userInfo?.user_id){
      this.getPackageList()
    }
    // this.backUrl = `${routeQuery.backUrl}&currentTag=${routeQuery.currentTag || ''}&order=${routeQuery.order || ''}&pattern=${routeQuery.pattern || ''}`
    this.getDetail()
    this.getSimilarMusicList()
  },
  methods: {
    // 获取相似音乐列表
    async getSimilarMusicList(){
      try {
        this.similarLoading = true
          const { data } = await fetchMusicSimilar(this.$route.params.id);
          this.similarLoading = false
          this.similarList = data
        } catch (e) {
          this.similarLoading = false
          console.log(e);
        }
    },
    handleChooseScene(scene){
      let index = this.chooseSceneList.indexOf(scene.sk_id);
      if(index === -1) {
        this.chooseSceneList.push(scene.sk_id)
        this.totalPrice += scene.price
      }
      else {
        this.chooseSceneList.splice(index, 1)
        this.totalPrice -= scene.price
      }
    },
    getPackageList() {
      fetchPackageList().then((res) => {
        const { data } = res
        let list = data[2].filter((i) => i.is_sum == 3)
        this.packageInfo = list.length ? list[0] : {}
      })
    },
    goPackage(){
      if (!(getToken() && getToken() !== '')) {
        this.$store.dispatch('user/openLogin')
        return
      }
      this.$router.push('/package?type=2')
    },
    immediatePayment(){
      if (!(getToken() && getToken() !== '')) {
        this.$store.dispatch('user/openLogin')
        return
      }
      if(this.packageInfo.is_contract == 1) return this.$message.warning('您已购买该商品，不可重复购买。')
      this.payGoodInfo = this.packageInfo
      const { goods_id } = this.packageInfo
      this.getPayQrCode(goods_id)
    },
    //未升级的订单
    getPayQrCode(item) {
      //个人会员
      this.openMemberID = item
      const goods_id = item
      const params = {
        goods_id: goods_id,
        share_token: window.localStorage.getItem('share_token')
          ? window.localStorage.getItem('share_token')
          : ''
      }
      Promise.all([
        fetchOrderPayByWxPay(params),
        fetchOrderPayByAliPay(params)
      ]).then((res) => {
        this.dialogVisiblemember = true
        this.isRetail = false
        this.wxPayOrderNo = res[0].data.order_no
        this.aliPayOrderNo = res[1].data.order_no
        this.wxPayQrCode = res[0].data.pay_url
        this.aliPayQrCode = res[1].data.pay_url
        this.handleViewOrderPayStatus(this.wxPayOrderNo, 'wxPayTimer')
        this.handleViewOrderPayStatus(this.aliPayOrderNo, 'aliPayOrderTimer')
      })
    },
    // 处理查看订单的支付状态
    async handleViewOrderPayStatus(order_no, name) {
      // 进入即刻清除定时器
      try {
        const { data } = await fetchOrderPayStatusByPackage({
          order_no,
          share_token: window.localStorage.getItem('share_token')
            ? window.localStorage.getItem('share_token')
            : ''
        })
        // 判断订单的状态 -1表示订单状态不存在 0表示订单支付成功
        if (data.status == -1) {
          // 添加定时器
          this[name] = setTimeout(() => {
            this.handleViewOrderPayStatus(order_no, name)
          }, payReloadTime)
          return
        } else if (data.status == 0) {
          this.dialogVisiblemember = false
          this.wxPayQrCode = ''
          this.aliPayQrCode = ''
          this.handleClearCountDown('wxPayTimer')
          this.handleClearCountDown('aliPayOrderTimer')
          this.handleNavMember()
          this.sendEventapi(1)
          this.$store.dispatch('user/getUserInfo')
        }
        this.handleClearCountDown(name)
      } catch (e) {
        console.log(e)
        this.handleClearCountDown(name)
      }
    },
     /**
     * 事件埋点
     */
    sendEventapi(payType) {
      const obj = {
        package_name: this.payGoodInfo.name,
        package_type: '企业',
        pay_type: payType == 1 ? '在线支付（支付宝/微信）' : '对公转账',
        op_date: formDateTime(),
        m_name: '',
        mid: '',
        reg_date: this.payGoodInfo.create_at,
        user_name: this.userInfo.name,
        user_id: this.userInfo.user_id
      }
      console.log(obj,'埋点数据')
      send_eventApi('e_pay', obj)
    },
    handleNavMember() {
      this.showLetter = true
    },
    closeLetter() {
      this.showLetter = false
    },
    // 清除定时器
    handleClearCountDown(name) {
      const timer = this[name]
      if (timer) {
        clearTimeout(timer)
        this[name] = null
      }
    },
    handlePayClose(){
      this.dialogVisiblemember = false
      this.handleClearCountDown('aliPayOrderTimer')
      this.handleClearCountDown('wxPayTimer')
      this.handleClearCountDown('retailTimer')
    },
    handlePublicSubmit() {
      this.wxPayQrCode = ''
      this.aliPayQrCode = ''
      this.handlePayClose()
      // this.handleNavMember()
      this.sendEventapi(2)
    },
    async handlePay(){
      if (!(getToken() && getToken() !== '')) {
        this.$store.dispatch('user/openLogin')
        return
      }
      if(!this.chooseSceneList.length) return this.$message.warning('请选择至少一个授权场景')

      try {
        this.payLoading = true
        const res = await addMusicOrder({
          music_id: this.$route.params.id,
          sk_ids: this.chooseSceneList
        })
        if (res.code === 0) {
          this.payGoodInfo = {...res.data, price: Number(res.data.pay_amount / 100 )}
          this.retailOrderId = res.data.order_id
          this.getMusicPayCode(res.data.order_id)
        }else {
          this.payLoading = false
        }
      } catch (error) {
        this.payLoading = false
        console.log(error)
      }
    },

    // 零售订单支付结果查询
    async handleViewRetailOrderResult(order_no, name) {
      // 进入即刻清除定时器
      try {
        const { data } = await fetchPayCodeResult({
          order_no,
          share_token: window.localStorage.getItem('share_token')
            ? window.localStorage.getItem('share_token')
            : ''
        })
        // 判断订单的状态 -1表示订单状态不存在 0表示订单支付成功
        if (data.status == -1) {
          // 添加定时器
          this[name] = setTimeout(() => {
            this.handleViewRetailOrderResult(order_no, name)
          }, payReloadTime)
          return
        } else if (data.status == 0) {
          this.dialogVisiblemember = false
          this.wxPayQrCode = ''
          this.aliPayQrCode = ''
          this.handleClearCountDown('retailTimer')
          this.$router.push(`/music/order-result/${this.retailOrderId}`)
        }
      } catch (e) {
        console.log(e)
        this.handleClearCountDown(name)
      }
    },
    // 获取音乐零售订单
    getMusicPayCode(order_id) {
      //个人会员
      Promise.all([
        fetchMusicPayCode({order_id, pay_way:'WECHAT'}),
        fetchMusicPayCode({order_id, pay_way:'ALI'})
      ]).then((res) => {
        // console.log(res);
        this.isRetail = true
        this.dialogVisiblemember = true
        this.payLoading = false
        this.retailOrderNO = res[0].data.order_no
        this.wxPayQrCode = res[0].data.pay_url
        this.aliPayQrCode = res[1].data.pay_url
        this.handleViewRetailOrderResult(this.retailOrderNO, 'retailTimer')
      })
    },
    async getDetail() {
      this.loading = true
      try {
        const res = await fetchMusicDetail(this.$route.params.id)
        if (res.code === 0) {
          this.musicData = res.data
          this.sceneList = res.data.pricing
          // 查找场景中价格最高的场景
          // let max = this.sceneList.reduce((max, obj) => {
          //   return obj.price > max ? obj.price : max
          // }, 0)
          // const index = this.sceneList.findIndex(i=> i.price == max)
          // this.totalPrice = max
          // this.chooseSceneList.push(this.sceneList[index].sk_id)

          this.breadData[2].breadcrumbName = res.data.name
          this.loading = false
        }
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.tooltip-c{
  width: 360px;
}
.music-detail-container {
  min-height: 100vh;
  min-width: 1000px;
  padding: 90px 0;
  .crumb-wapper{
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .content {
    width: auto;
    display: flex;
    margin: 0 auto;
    width: 1200px;
    .music-info {
      display: flex;
      flex-direction: column;
      width: 180px;
      .header-img{
        width: 180px;
        height: 180px;
        border-radius: 8px;
        position: relative;
        .img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
          background: rgba(0, 0, 0, 0.2);
        }
        .audio-status {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 8px;
          background: rgba(0, 0, 0, .2);
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            width: 40px;
            height: 40px;
          }
        }
      }
      h3 {
        margin: 12px 0 6px 0;
        color: #333333;
        font-weight: bold;
        font-size: 16px;
      }
      h4 {
        margin: 0;
        font-size: 12px;
        color: #999;
      }
      span {
        cursor: pointer;
        margin-top: 16px;
        color: #0061F3;
        font-size: 14px;
        text-decoration:underline;
      }

    }
    .scene-info {
      width: 600px;
      height: 290px;
      display: flex;
      flex-wrap: wrap;
      margin: 0 25px 28px;
      .scene-items {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include size(290px, 86px);
        border-radius: 10px;
        border: 1px solid rgba(0,0,0,0.1);
        color: #333333;
        margin-bottom: 16px;
        position: relative;
        padding: 0 20px;
        &:nth-child(2n+1){
          margin-right: 20px;
        }
        &:hover {
          &::after {
            content: url("~@/assets/svg/scene_hover_icon.svg");
            position: absolute;
            bottom: -4px;
            right: 0;
          }
        }
        .name {
          div {
            @include center-middle-flex($justify: flex-start);
            margin-bottom: 6px;
            span {
              font-size: 16px;
            }
            .qus{
              margin-left: 4px;
              @include size(14px, 14px);
            }
          }
          i {
            font-style: normal;
            color: #999999;
            font-size: 14px;
            span {
              color: #E52A0D;
            }
          }
        }

        .price {
          margin-left: 20px;
          font-size: 20px;
          font-family: alipuhuir;
          font-weight: bold

        }
      }
      .scene-active {
        border: 2px solid #E52A0D;
        &::after {
          content: url("~@/assets/svg/scene_choose_icon.svg")!important;
          position: absolute;
          bottom: -5px!important;
          right: 0;
        }
      }
    }
    .pay-info {
      position: relative;
      height: 140px;
      margin-left: 25px;
      .tip{
        position: absolute;
        @include size(600px, 60px);
        background: rgba(255,142,20,0.05);
        border-radius: 8px;
        color: #FF8E14;
        font-size: 14px;
        top: 0;
        left: 0;
        padding: 12px 0 0 20px;
        i{
          margin-right: 8px;
        }
      }
      .pay {
        position: absolute;
        @include size(600px, 100px);
        border-radius: 8px;
        background: #fff;
        border: 1px solid #EDEDED;
        bottom: 0;
        left: 0;
        padding: 0 30px 0 32px;
        @include center-middle-flex($justify: space-between);
        span{
          font-size: 16px;
          span{
            color: #E52A0D;
            font-size: 20px;
            font-weight: 600;
            i{
              font-style: normal;
              font-size: 16px;
            }
          }
        }
        .retail-pay{
          width: 120px;
          height: 36px;
          line-height: 36px;
          text-align: center;
          background: rgba(229,42,13,0.1);
          border-radius: 18px;
          font-size: 14px;
          font-weight: 500;
          color: #E52A0D;
          padding: 0;
          border: none;
        }
      }
    }
    .vip-info{
      @include size(366px, 473px);
      background: url("~@/assets/image/music/year_bg.png") no-repeat;
      background-size: cover;
      @include center-middle-flex;
      flex-direction: column;
      color: #FFFFFF;
      font-size: 14px;
      .name {
        margin-top: 20px;
        font-weight: 600;
        font-size: 24px;
        text-shadow: 0px 2px 7px rgba(0,0,0,0.3);
        img{
          cursor: pointer;
          @include size(18px, 18px);
          margin-left: 8px;
        }
      }
      .price{
        font-family: alipuhuir;
        font-size: 70px;
        margin-bottom: -12px;
        text-shadow: 0px 2px 7px rgba(0,0,0,0.2);
        span{
          font-size: 40px;
          font-weight: bold;
        }
        i{
          font-style: normal;
          font-size: 20px;
          text-shadow: none;
        }
      }
      .desc {
        display: flex;
        margin:  40px 0;
        div{
          width: 84px;
          @include center-middle-flex;
          flex-direction: column;
          img{
            @include size(60px, 60px);
            margin-bottom: 12px;
          }
          p{
            margin: 0;
            font-weight: 600;
            text-align: center;
          }
          &:nth-child(1){
            p{
              width: 76px;
            }
          }
          &:nth-child(2){
            margin: 0 24px;
          }
        }

      }
      .pay {
        cursor: pointer;
        width: 280px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: #FFFFFF;
        border-radius: 25px;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 8px;
        color: #E52A0D;
      }

    }
  }
  .recommend {
    margin: 60px auto;
    width: 1200px;
    .title {
      font-size: 36px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 50px;
      margin-bottom: 36px;
    }
  }
}
</style>
